export interface Config {
    title: string;
    img: string;
    color: string;
    link?: string;
}
const networkList: Config[] = [
    {
        title: "BSC",
        img: "bsc",
        color: "#f0b90b",
        link: "#"
    },
]

export default networkList;